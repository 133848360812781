.form.login-form {
  min-width: 600px;
}

.form-with-title {
  .form-title {
    text-align: center;
    margin-bottom: 25px;
  }

  .form {
    max-width: 1200px;
    margin: $fitToCenter;
  }
}

.radio-buttons__wrapper {
  font-family: "Roboto", sans-serif;
  color: $lightBrownFilled;

  .radio-buttons__label-section {
    margin-bottom: 15px;

    span {
      font-size: 18px;
    }
  }

  .radio-buttons__buttons-section {
    .radio-buttons__buttons-section__item {
      height: 40px;
      width: 400px;

      .radio-input {
        display: none;
      }

      .radio-input:checked + .radio-input__label::after {
        transform: scale(1);
      }

      .radio-input:checked + .radio-input__label {
        color: $darkGreenFilled;
        font-weight: 500;
      }

      .radio-input__label {
        transition: all 0.3s ease 0s;
        width: 300px;
        font-size: 16px;
        line-height: 140%;
        display: inline-flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        &:before {
          content: '';
          align-self: flex-start;
          flex: 0 0 24px;
          height: 24px;
          background-color: $almostWhiteFilled;
          border: 1px solid $lightBrownFilled;
          border-radius: 50%;
          margin: 0 10px 0 0;
        }

        &:after {
          transition: all 0.3s ease 0s;
          content: '';
          width: 16px;
          height: 16px;
          position: absolute;
          top: 4px;
          left: 4px;
          background-color: $lightGreenFilled;
          border-radius: 50%;
          transform: scale(0);
        }
      }
    }
  }
}

.form-item {
  margin: 0 auto 30px;
  max-width: 1200px;
  position: relative;

  .form-item__label {
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: white;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    color: $lightBrownTransparent;
    font-size: 16px;
    transition: transform 150ms ease-out, font-size 150ms ease-out, color 150ms ease-out;
    white-space: nowrap;
    overflow: hidden; /* Скрыть содержимое, выходящее за пределы элемента */
    text-overflow: ellipsis; /* Добавить многоточие, если текст не помещается полностью */
  }

  .focused.form-item__label {
    transform: translateY(-125%);
    font-size: 14px;
    color: $lightBrownFilled;
  }

  .form__label {
    font-size: 18px;
    display: block;
    margin: 0 0 10px 0;

    span {
      font-size: 14px;
    }
  }

  .form__input {
    height: 50px;
    display: block;
    padding: 0 20px;
    border-radius: 10px;
    width: 100%;
    border: 1px solid $lightBrownTransparent;
    text-align: center;
    font-size: 18px;
    color: $darkGreenFilled;
    background-color: $almostWhiteTransparent;

    &::placeholder {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.03em;
      color: $lightBrownTransparent;
    }

    &:focus {
      border: 1px solid $lightBrownFilled;
    }
  }

  .form__input.forgot-password {
    min-width: 450px;
  }

  .form__input._error {
    box-shadow: 0 0 5px red;
  }

  .right-margin-20 {
    margin-right: 20px;
  }

  .small-fontsize {
    font-size: 12px;

    &::placeholder {
      font-size: 12px;
    }
  }

  .form__input.range {
    width: 40%;
  }

  .form__textarea {
    min-height: 200px;
    max-height: 400px;
    display: block;
    border-radius: 10px;
    width: 100%;
    border: 1px solid $lightBrownTransparent;
    resize: none;
    font-size: 18px;
    color: $darkGreenFilled;
    line-height: 1.3em;
    padding: 10px 20px 0;

    /*&::placeholder {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.03em;
      line-height: 50px;
      text-align: center;
      color: $lightBrownTransparent;
    }*/

    &:focus {
      border: 1px solid $lightBrownFilled;
    }
  }

  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;

    .checkbox__input {
      display: none;
    }

    .checkbox__input:checked + .checkbox__label::after {
      transform: scale(1);
    }

    .checkbox__label {
      font-size: 16px;
      line-height: 140%;
      display: inline-flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      &:before {
        content: '';
        align-self: flex-start;
        flex: 0 0 24px;
        height: 24px;
        background-color: $almostWhiteFilled;
        border: 1px solid $lightBrownFilled;
        border-radius: 4px;
        margin: 0 10px 0 0;
      }

      &:after {
        transition: all 0.3s ease 0s;
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        top: 4px;
        left: 4px;
        background-color: $lightGreenFilled;
        border-radius: 4px;
        transform: scale(0);
      }

      span {
        display: inline-block;
        max-width: 400px;
        white-space: pre-wrap; /* Переводит строки внутри текста и осуществляет перенос по словам */
        word-wrap: break-word; /* Переносит длинные слова на новую строку */
      }
    }
  }

  .checkbox.as-table-header {
    margin-top: 20px;

    span {
      font-weight: 500;
      font-size: 18px;
    }
  }

  .checkbox._error {
    .checkbox__label::before {
      box-shadow: 0 0 5px red;
    }
  }
}

.checkbox.multiselect {
  display: flex;
  width: 100%;
  max-width: 400px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  min-height: 30px;

  .checkbox-header {
    //margin-bottom: 10px;
    span {
      line-height: 40px;
      color: $lightBrownFilled;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .custom-checkbox.green{
    color: $darkGreenFilled;
    font-weight: 500;
  }

  .custom-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-left: 35px;
    font-size: 16px;
    user-select: none;
    min-height: 34px;
    color: $lightBrownFilled;

    .custom-checkbox-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .custom-checkbox-box {
      position: absolute;
      left: 0;
      height: 25px;
      width: 25px;
      border: 5px solid $almostWhiteFilled;
      outline: 1px solid $lightBrownFilled;
      border-radius: 4px;
      transition: all 0.3s ease 0s;
    }

    .custom-checkbox-box:after {
      content: '';
      width: 18px;
      height: 18px;
      position: absolute;
      top: -1px;
      left: -1px;
      background-color: $lightGreenFilled;
      border-radius: 4px;
      transform: scale(0);
      transition: all 0.3s ease 0s;
    }
  }

  .custom-checkbox-input:checked ~ .custom-checkbox-box:after {
    transform: scale(1);
  }
}

.form-item.group {
  display: flex;
  justify-content: space-between;
}

.form-item.forgot-password-container {
  margin: -10px auto -20px;
  text-align: right;
}

.form__button {
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  background-color: $orangeFilled;
  border: 2px solid $orangeFilled;
  font-size: 16px;
  //text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
  color: $almostWhiteFilled;
  transition: all 0.3s ease 0s;
  margin: 40px auto 0;

  &:hover {
    background-color: $almostWhiteFilled;
    color: $orangeFilled;
  }
}

.form-item.range .range-line {
  display: flex;
  justify-content: center;

  .range-item {
    position: relative;
    display: flex;
    justify-content: center;

    .form__input {
      width: 95%;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.breed-section__wrapper {
  margin: 0 auto 30px;
  max-width: 1200px;

  .breed-section {
    .breed-section__header {
      margin-bottom: 20px;

      h2 {
        color: $lightBrownFilled;
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
}

.breed-section__aggregated-mark {
  margin-bottom: 30px;
}

.breed-section__extended_params {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.radio-fieldset {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 400px;

  legend {
    color: $lightBrownFilled;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
  }

  .radio-option {
    margin-right: 30px;
    font-size: 16px;
    line-height: 32px;
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    input[type="radio"] + label::after {
      content: '';
      width: 16px;
      height: 16px;
      background-color: $lightGreenFilled;
      border-radius: 50%;
      position: absolute;
      top: 4px;
      left: 4px;
      transform: scale(0);
      opacity: 0; /* Изначально скрыто */
      transition: transform 0.3s ease, opacity 0.3s ease;
    }

    input[type="radio"]:checked + label::after {
      transform: scale(1);
      opacity: 1; /* Появляется при выборе */
      transition: transform 0.3s ease, opacity 0.3s ease;
    }

    input[type="radio"]:checked + label {
      color: $darkGreenFilled;
      font-weight: 500;
    }

    label {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      color: $lightBrownFilled;

      &:before {
        content: '';
        align-self: flex-start;
        flex: 0 0 24px;
        height: 24px;
        width: 24px;
        background-color: $almostWhiteFilled;
        border: 1px solid $lightBrownFilled;
        border-radius: 50%;
        margin-right: 10px;
        box-sizing: border-box;
      }
    }
  }
}

.radio-fieldset.wide {
  width: 100%;
  max-width: 1200px;
}

.gender-form-params__wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .gender-form-params {
    padding-left: 23px;
    padding-right: 23px;
    border-radius: 10px;

    .title-by-center {
      h3 {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .gender-form-params__header {
      display: flex;
      justify-content: center;

      span {
        line-height: 40px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .gender-form-params.male {
    border: 3px solid $maleColor;
    margin-right: 30px;

    .gender-form-params__header {
      span {
        color: $maleColor;
      }

    }
  }

  .gender-form-params.female {
    border: 3px solid $femaleColor;

    .gender-form-params__header {
      span {
        color: $femaleColor;
      }
    }
  }

}

.form-item.search-form {
  max-width: 100%;
}

.form__error {
  line-height: 22px;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  white-space: pre-wrap;

  span {
    color: red;
  }
}

.form__error.margin-30 {
  margin-top: -30px;
}

.breed-input {
  .breed-input__help-link {
    margin-top: -25px;
    margin-bottom: 20px;
    text-align: right;

    .link.small {
      padding: 0;
    }
  }
}

.breed-input__tip-wrapper {
  cursor: default;
  text-align: justify;
  color: $lightBrownFilled;

  .breed-input__tip {
    font-size: 16px;
    line-height: 24px;

    .breed-input__tip-variant {
      white-space: nowrap;
      font-weight: 600;
      font-size: 18px;
    }

    .breed-input__tip-variant.selectable {
      cursor: pointer;

      &:hover {
        color: $darkGreenFilled;
      }
    }
  }
}
.input-with-options-selected-items__wrapper.hidden {
  display: none;
}

.input-with-options-selected-items__wrapper {
  opacity: 1;
  position: relative;
  margin: 0 15px 15px;
  max-width: 1170px;
  display: flex;

  .close_btn {
    display: inline-block;
    width: 20px;
    position: relative;
    padding-left: 10px;
    line-height: 30px;
    top: -4px;
  }

  .input-with-options__selected-item {
    margin-right: 10px;
    line-height: 30px;
    border: 1px solid $lightBrown03;
    border-radius: 10px;
    background-color: $lightBrown01;
    cursor: default;
    span {
      padding: 10px;
      color: $lightBrownFilled;
    }
  }
}

.input-with-options-wrapper {
  position: relative;
  margin: 0 auto 30px;
  max-width: 1200px;

  .form__input {
    padding-left: 50px;
  }

  .input-with-options__trash-wrapper {
    position: absolute;
    left: 15px;
    top: 14px;
    color: $lightBrownTransparent;
    transition: all 0.3s ease 0s;
    cursor: pointer;

    &:hover {
      color: $lightBrownFilled;
    }
  }
}

.input-field-arrow__wrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  background-color: $lightBrownTransparent;
  border-radius: 0 10px 10px 0;
  cursor: pointer;

  .input-field-arrow {
    position: absolute;
    right: 19px;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid $almostWhiteTransparent;
    transition: transform 0.3s ease; /* Плавный переход для трансформации */
  }

  .input-field-arrow.collapsed {
    transform: translateY(-50%) rotate(180deg);
  }

  &:hover .input-field-arrow {
    border-top: 10px solid $lightBrownFilled;
  }
}

.dropdown-menu {
  position: absolute;
  width: 100%;
  margin-top: 10px;
  max-height: 150px;
  overflow-y: hidden;
  border: 1px solid $lightBrownTransparent;
  border-radius: 10px;
  background: $almostWhiteFilled;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  z-index: 1000;
  transition: all 0.3s ease;
  box-sizing: border-box;
  padding: 4px;

  .dropdown-menu__content {
    min-height: 40px;
    max-height: 140px;
    overflow-y: auto;
    z-index: 1000;
    padding-right: 4px; /* Размер полосы прокрутки + внутренний отступ */
    border-radius: inherit; /* Наследуем округленные углы родителя */

    &::-webkit-scrollbar {
      width: 12px; /* Ширина скроллбара */
    }

    &::-webkit-scrollbar-track {
      background: $almostWhiteFilled; /* Цвет дорожки */
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $lightBrownTransparent; /* Цвет ползунка */
      border-radius: 10px;
      border: 2px solid $almostWhiteFilled; /* Дополнительное пространство вокруг ползунка */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $lightBrownFilled; /* Цвет ползунка при наведении */
    }
  }
}

.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}


.dropdown-item {
  padding: 8px;
  cursor: pointer;
  background: $almostWhiteFilled;
  z-index: 9999;
}

.dropdown-item:hover {
  background: $lightBrown01;
}

.dropdown-item.no-items {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;

  span {
    color: $lightBrownFilled;
  }
}

.dropdown-item.no-items:hover {
  background: $almostWhiteFilled;
}

.pagination-wrapper {
  margin-top: 20px;

  .pagination {
    display: flex;
    justify-content: center;
    line-height: 40px;

    li.page-item.active {
      .page-link {
        background-color: $lightBrownTransparent;
        border-color: $lightBrownTransparent;
      }
    }

    .page-link {
      display: inline-block;
      width: 40px;
      text-align: center;
      color: $lightBrownFilled;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      border-radius: 10px;

      &:hover {
        background-color: $lightBrown01;
      }
    }
  }
}

@import "../mobile/mobile-form.scss";
