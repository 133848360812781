@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap");
@import url("https://unicons.iconscout.com/release/v4.0.0/css/line.css");
/**
 * Цвета градиентов
 */
* {
  padding: 0;
  margin: 0;
  border: 0;
}

/*
border-box при расчете размера элемента включает padding и border
и не включает margin
*/
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 100%;
  line-height: 1.15;
  letter-spacing: 0.01em;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited, a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  font-weight: 400;
}

.form.login-form {
  min-width: 600px;
}

.form-with-title .form-title {
  text-align: center;
  margin-bottom: 25px;
}
.form-with-title .form {
  max-width: 1200px;
  margin: 0 auto;
}

.radio-buttons__wrapper {
  font-family: "Roboto", sans-serif;
  color: rgb(128, 115, 117);
}
.radio-buttons__wrapper .radio-buttons__label-section {
  margin-bottom: 15px;
}
.radio-buttons__wrapper .radio-buttons__label-section span {
  font-size: 18px;
}
.radio-buttons__wrapper .radio-buttons__buttons-section .radio-buttons__buttons-section__item {
  height: 40px;
  width: 400px;
}
.radio-buttons__wrapper .radio-buttons__buttons-section .radio-buttons__buttons-section__item .radio-input {
  display: none;
}
.radio-buttons__wrapper .radio-buttons__buttons-section .radio-buttons__buttons-section__item .radio-input:checked + .radio-input__label::after {
  transform: scale(1);
}
.radio-buttons__wrapper .radio-buttons__buttons-section .radio-buttons__buttons-section__item .radio-input:checked + .radio-input__label {
  color: rgb(27, 44, 0);
  font-weight: 500;
}
.radio-buttons__wrapper .radio-buttons__buttons-section .radio-buttons__buttons-section__item .radio-input__label {
  transition: all 0.3s ease 0s;
  width: 300px;
  font-size: 16px;
  line-height: 140%;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.radio-buttons__wrapper .radio-buttons__buttons-section .radio-buttons__buttons-section__item .radio-input__label:before {
  content: "";
  align-self: flex-start;
  flex: 0 0 24px;
  height: 24px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(128, 115, 117);
  border-radius: 50%;
  margin: 0 10px 0 0;
}
.radio-buttons__wrapper .radio-buttons__buttons-section .radio-buttons__buttons-section__item .radio-input__label:after {
  transition: all 0.3s ease 0s;
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: rgb(77, 119, 1);
  border-radius: 50%;
  transform: scale(0);
}

.form-item {
  margin: 0 auto 30px;
  max-width: 1200px;
  position: relative;
}
.form-item .form-item__label {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: white;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  color: rgba(128, 115, 117, 0.5);
  font-size: 16px;
  transition: transform 150ms ease-out, font-size 150ms ease-out, color 150ms ease-out;
  white-space: nowrap;
  overflow: hidden; /* Скрыть содержимое, выходящее за пределы элемента */
  text-overflow: ellipsis; /* Добавить многоточие, если текст не помещается полностью */
}
.form-item .focused.form-item__label {
  transform: translateY(-125%);
  font-size: 14px;
  color: rgb(128, 115, 117);
}
.form-item .form__label {
  font-size: 18px;
  display: block;
  margin: 0 0 10px 0;
}
.form-item .form__label span {
  font-size: 14px;
}
.form-item .form__input {
  height: 50px;
  display: block;
  padding: 0 20px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid rgba(128, 115, 117, 0.5);
  text-align: center;
  font-size: 18px;
  color: rgb(27, 44, 0);
  background-color: rgba(255, 255, 255, 0.95);
}
.form-item .form__input::placeholder {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.03em;
  color: rgba(128, 115, 117, 0.5);
}
.form-item .form__input:focus {
  border: 1px solid rgb(128, 115, 117);
}
.form-item .form__input.forgot-password {
  min-width: 450px;
}
.form-item .form__input._error {
  box-shadow: 0 0 5px red;
}
.form-item .right-margin-20 {
  margin-right: 20px;
}
.form-item .small-fontsize {
  font-size: 12px;
}
.form-item .small-fontsize::placeholder {
  font-size: 12px;
}
.form-item .form__input.range {
  width: 40%;
}
.form-item .form__textarea {
  min-height: 200px;
  max-height: 400px;
  display: block;
  border-radius: 10px;
  width: 100%;
  border: 1px solid rgba(128, 115, 117, 0.5);
  resize: none;
  font-size: 18px;
  color: rgb(27, 44, 0);
  line-height: 1.3em;
  padding: 10px 20px 0;
  /*&::placeholder {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.03em;
    line-height: 50px;
    text-align: center;
    color: $lightBrownTransparent;
  }*/
}
.form-item .form__textarea:focus {
  border: 1px solid rgb(128, 115, 117);
}
.form-item .checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-item .checkbox .checkbox__input {
  display: none;
}
.form-item .checkbox .checkbox__input:checked + .checkbox__label::after {
  transform: scale(1);
}
.form-item .checkbox .checkbox__label {
  font-size: 16px;
  line-height: 140%;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.form-item .checkbox .checkbox__label:before {
  content: "";
  align-self: flex-start;
  flex: 0 0 24px;
  height: 24px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(128, 115, 117);
  border-radius: 4px;
  margin: 0 10px 0 0;
}
.form-item .checkbox .checkbox__label:after {
  transition: all 0.3s ease 0s;
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: rgb(77, 119, 1);
  border-radius: 4px;
  transform: scale(0);
}
.form-item .checkbox .checkbox__label span {
  display: inline-block;
  max-width: 400px;
  white-space: pre-wrap; /* Переводит строки внутри текста и осуществляет перенос по словам */
  word-wrap: break-word; /* Переносит длинные слова на новую строку */
}
.form-item .checkbox.as-table-header {
  margin-top: 20px;
}
.form-item .checkbox.as-table-header span {
  font-weight: 500;
  font-size: 18px;
}
.form-item .checkbox._error .checkbox__label::before {
  box-shadow: 0 0 5px red;
}

.checkbox.multiselect {
  display: flex;
  width: 100%;
  max-width: 400px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  min-height: 30px;
}
.checkbox.multiselect .checkbox-header span {
  line-height: 40px;
  color: rgb(128, 115, 117);
  font-weight: 500;
  font-size: 16px;
}
.checkbox.multiselect .custom-checkbox.green {
  color: rgb(27, 44, 0);
  font-weight: 500;
}
.checkbox.multiselect .custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  font-size: 16px;
  user-select: none;
  min-height: 34px;
  color: rgb(128, 115, 117);
}
.checkbox.multiselect .custom-checkbox .custom-checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox.multiselect .custom-checkbox .custom-checkbox-box {
  position: absolute;
  left: 0;
  height: 25px;
  width: 25px;
  border: 5px solid rgb(255, 255, 255);
  outline: 1px solid rgb(128, 115, 117);
  border-radius: 4px;
  transition: all 0.3s ease 0s;
}
.checkbox.multiselect .custom-checkbox .custom-checkbox-box:after {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  top: -1px;
  left: -1px;
  background-color: rgb(77, 119, 1);
  border-radius: 4px;
  transform: scale(0);
  transition: all 0.3s ease 0s;
}
.checkbox.multiselect .custom-checkbox-input:checked ~ .custom-checkbox-box:after {
  transform: scale(1);
}

.form-item.group {
  display: flex;
  justify-content: space-between;
}

.form-item.forgot-password-container {
  margin: -10px auto -20px;
  text-align: right;
}

.form__button {
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  background-color: rgb(244, 101, 0);
  border: 2px solid rgb(244, 101, 0);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
  margin: 40px auto 0;
}
.form__button:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(244, 101, 0);
}

.form-item.range .range-line {
  display: flex;
  justify-content: center;
}
.form-item.range .range-line .range-item {
  position: relative;
  display: flex;
  justify-content: center;
}
.form-item.range .range-line .range-item .form__input {
  width: 95%;
  margin-left: 10px;
  margin-right: 10px;
}

.breed-section__wrapper {
  margin: 0 auto 30px;
  max-width: 1200px;
}
.breed-section__wrapper .breed-section .breed-section__header {
  margin-bottom: 20px;
}
.breed-section__wrapper .breed-section .breed-section__header h2 {
  color: rgb(128, 115, 117);
  font-size: 22px;
  font-weight: 600;
}

.breed-section__aggregated-mark {
  margin-bottom: 30px;
}

.breed-section__extended_params {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.radio-fieldset {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 400px;
}
.radio-fieldset legend {
  color: rgb(128, 115, 117);
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
}
.radio-fieldset .radio-option {
  margin-right: 30px;
  font-size: 16px;
  line-height: 32px;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.radio-fieldset .radio-option input[type=radio] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-fieldset .radio-option input[type=radio] + label::after {
  content: "";
  width: 16px;
  height: 16px;
  background-color: rgb(77, 119, 1);
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  transform: scale(0);
  opacity: 0; /* Изначально скрыто */
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.radio-fieldset .radio-option input[type=radio]:checked + label::after {
  transform: scale(1);
  opacity: 1; /* Появляется при выборе */
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.radio-fieldset .radio-option input[type=radio]:checked + label {
  color: rgb(27, 44, 0);
  font-weight: 500;
}
.radio-fieldset .radio-option label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: rgb(128, 115, 117);
}
.radio-fieldset .radio-option label:before {
  content: "";
  align-self: flex-start;
  flex: 0 0 24px;
  height: 24px;
  width: 24px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(128, 115, 117);
  border-radius: 50%;
  margin-right: 10px;
  box-sizing: border-box;
}

.radio-fieldset.wide {
  width: 100%;
  max-width: 1200px;
}

.gender-form-params__wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.gender-form-params__wrapper .gender-form-params {
  padding-left: 23px;
  padding-right: 23px;
  border-radius: 10px;
}
.gender-form-params__wrapper .gender-form-params .title-by-center h3 {
  font-size: 16px;
  font-weight: 400;
}
.gender-form-params__wrapper .gender-form-params .gender-form-params__header {
  display: flex;
  justify-content: center;
}
.gender-form-params__wrapper .gender-form-params .gender-form-params__header span {
  line-height: 40px;
  font-size: 18px;
  font-weight: 600;
}
.gender-form-params__wrapper .gender-form-params.male {
  border: 3px solid rgba(0, 123, 255, 0.8);
  margin-right: 30px;
}
.gender-form-params__wrapper .gender-form-params.male .gender-form-params__header span {
  color: rgba(0, 123, 255, 0.8);
}
.gender-form-params__wrapper .gender-form-params.female {
  border: 3px solid rgba(255, 105, 180, 0.8);
}
.gender-form-params__wrapper .gender-form-params.female .gender-form-params__header span {
  color: rgba(255, 105, 180, 0.8);
}

.form-item.search-form {
  max-width: 100%;
}

.form__error {
  line-height: 22px;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  white-space: pre-wrap;
}
.form__error span {
  color: red;
}

.form__error.margin-30 {
  margin-top: -30px;
}

.breed-input .breed-input__help-link {
  margin-top: -25px;
  margin-bottom: 20px;
  text-align: right;
}
.breed-input .breed-input__help-link .link.small {
  padding: 0;
}

.breed-input__tip-wrapper {
  cursor: default;
  text-align: justify;
  color: rgb(128, 115, 117);
}
.breed-input__tip-wrapper .breed-input__tip {
  font-size: 16px;
  line-height: 24px;
}
.breed-input__tip-wrapper .breed-input__tip .breed-input__tip-variant {
  white-space: nowrap;
  font-weight: 600;
  font-size: 18px;
}
.breed-input__tip-wrapper .breed-input__tip .breed-input__tip-variant.selectable {
  cursor: pointer;
}
.breed-input__tip-wrapper .breed-input__tip .breed-input__tip-variant.selectable:hover {
  color: rgb(27, 44, 0);
}

.input-with-options-selected-items__wrapper.hidden {
  display: none;
}

.input-with-options-selected-items__wrapper {
  opacity: 1;
  position: relative;
  margin: 0 15px 15px;
  max-width: 1170px;
  display: flex;
}
.input-with-options-selected-items__wrapper .close_btn {
  display: inline-block;
  width: 20px;
  position: relative;
  padding-left: 10px;
  line-height: 30px;
  top: -4px;
}
.input-with-options-selected-items__wrapper .input-with-options__selected-item {
  margin-right: 10px;
  line-height: 30px;
  border: 1px solid rgba(128, 115, 117, 0.3);
  border-radius: 10px;
  background-color: rgba(128, 115, 117, 0.1);
  cursor: default;
}
.input-with-options-selected-items__wrapper .input-with-options__selected-item span {
  padding: 10px;
  color: rgb(128, 115, 117);
}

.input-with-options-wrapper {
  position: relative;
  margin: 0 auto 30px;
  max-width: 1200px;
}
.input-with-options-wrapper .form__input {
  padding-left: 50px;
}
.input-with-options-wrapper .input-with-options__trash-wrapper {
  position: absolute;
  left: 15px;
  top: 14px;
  color: rgba(128, 115, 117, 0.5);
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
.input-with-options-wrapper .input-with-options__trash-wrapper:hover {
  color: rgb(128, 115, 117);
}

.input-field-arrow__wrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  background-color: rgba(128, 115, 117, 0.5);
  border-radius: 0 10px 10px 0;
  cursor: pointer;
}
.input-field-arrow__wrapper .input-field-arrow {
  position: absolute;
  right: 19px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid rgba(255, 255, 255, 0.95);
  transition: transform 0.3s ease; /* Плавный переход для трансформации */
}
.input-field-arrow__wrapper .input-field-arrow.collapsed {
  transform: translateY(-50%) rotate(180deg);
}
.input-field-arrow__wrapper:hover .input-field-arrow {
  border-top: 10px solid rgb(128, 115, 117);
}

.dropdown-menu {
  position: absolute;
  width: 100%;
  margin-top: 10px;
  max-height: 150px;
  overflow-y: hidden;
  border: 1px solid rgba(128, 115, 117, 0.5);
  border-radius: 10px;
  background: rgb(255, 255, 255);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  z-index: 1000;
  transition: all 0.3s ease;
  box-sizing: border-box;
  padding: 4px;
}
.dropdown-menu .dropdown-menu__content {
  min-height: 40px;
  max-height: 140px;
  overflow-y: auto;
  z-index: 1000;
  padding-right: 4px; /* Размер полосы прокрутки + внутренний отступ */
  border-radius: inherit; /* Наследуем округленные углы родителя */
}
.dropdown-menu .dropdown-menu__content::-webkit-scrollbar {
  width: 12px; /* Ширина скроллбара */
}
.dropdown-menu .dropdown-menu__content::-webkit-scrollbar-track {
  background: rgb(255, 255, 255); /* Цвет дорожки */
  border-radius: 10px;
}
.dropdown-menu .dropdown-menu__content::-webkit-scrollbar-thumb {
  background-color: rgba(128, 115, 117, 0.5); /* Цвет ползунка */
  border-radius: 10px;
  border: 2px solid rgb(255, 255, 255); /* Дополнительное пространство вокруг ползунка */
}
.dropdown-menu .dropdown-menu__content::-webkit-scrollbar-thumb:hover {
  background: rgb(128, 115, 117); /* Цвет ползунка при наведении */
}

.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
  background: rgb(255, 255, 255);
  z-index: 9999;
}

.dropdown-item:hover {
  background: rgba(128, 115, 117, 0.1);
}

.dropdown-item.no-items {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}
.dropdown-item.no-items span {
  color: rgb(128, 115, 117);
}

.dropdown-item.no-items:hover {
  background: rgb(255, 255, 255);
}

.pagination-wrapper {
  margin-top: 20px;
}
.pagination-wrapper .pagination {
  display: flex;
  justify-content: center;
  line-height: 40px;
}
.pagination-wrapper .pagination li.page-item.active .page-link {
  background-color: rgba(128, 115, 117, 0.5);
  border-color: rgba(128, 115, 117, 0.5);
}
.pagination-wrapper .pagination .page-link {
  display: inline-block;
  width: 40px;
  text-align: center;
  color: rgb(128, 115, 117);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
}
.pagination-wrapper .pagination .page-link:hover {
  background-color: rgba(128, 115, 117, 0.1);
}

@media screen and (max-width: 1080px) {
  .form.login-form {
    min-width: 100%;
    max-height: 600px;
  }
  .form-item .form-item__label {
    width: min-content;
    max-width: 235px;
    line-height: 22px;
  }
  .form-item .form__input {
    text-align: inherit;
    padding-left: 13px;
    min-width: 100%;
  }
  .form-item .form__input.forgot-password {
    min-width: 316px;
  }
  .form-item.range .form__input {
    min-width: min-content;
  }
  .form-item.range .form__input.forgot-password {
    min-width: 316px;
  }
  .input-with-options-wrapper {
    position: relative;
    margin: 0 auto 30px;
    max-width: 1200px;
  }
  .input-with-options-wrapper .form__input {
    padding-left: 50px;
  }
  .input-with-options-wrapper .input-with-options__trash-wrapper {
    position: absolute;
    left: 15px;
    top: 13px;
    color: rgba(128, 115, 117, 0.5);
    transition: all 0.3s ease 0s;
    cursor: pointer;
  }
  .input-with-options-wrapper .input-with-options__trash-wrapper:hover {
    color: rgb(128, 115, 117);
  }
}
/**
 * Цвета градиентов
 */
.tabs-wrapper {
  max-width: 500px;
  display: flex;
  justify-content: center;
  margin: 0 auto 30px;
}
.tabs-wrapper .tabs-item {
  border-color: rgb(27, 44, 0);
  border-radius: 10px 10px 0 0;
  border-style: solid;
  border-bottom: none;
  border-width: 1px;
  cursor: pointer;
  color: rgb(27, 44, 0);
  height: 50px;
  width: 250px;
  margin-right: 3px;
  text-align: center;
}
.tabs-wrapper .tabs-item span {
  line-height: 50px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}
.tabs-wrapper .tabs-item:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(27, 44, 0);
  border: 1px solid rgb(27, 44, 0);
}
.tabs-wrapper .tabs-item.active {
  color: rgb(255, 255, 255);
  background-color: rgb(27, 44, 0);
}
.tabs-wrapper .tabs-item.disabled {
  border-color: rgba(128, 115, 117, 0.5);
  color: rgba(128, 115, 117, 0.5);
  background-color: transparent;
  pointer-events: none;
}

@media screen and (max-width: 1080px) {
  .tabs-wrapper {
    max-width: 400px;
  }
  .tabs-wrapper .tabs-item {
    max-width: 250px;
  }
  .tabs-wrapper .tabs-item span {
    font-size: 14px;
  }
}
/**
 * Цвета градиентов
 */
.language-selector {
  width: 170px;
  border-radius: 10px;
  color: rgb(27, 44, 0);
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease 0s;
}
.language-selector .language-selector__select-btn {
  height: 50px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  justify-content: flex-end;
  display: flex;
}
.language-selector .language-selector__select-btn i {
  font-size: 31px;
  transition: transform 0.3s linear;
}
.language-selector .language-selector__select-btn i._hide {
  display: none;
}
.language-selector .language-selector__select-btn span.language-selector__selected-language {
  height: 30px;
  line-height: 30px;
  display: block;
}
.language-selector .language-selector__select-btn .language-selector__selected-language.english::before {
  content: "";
  background: url(/public/images/flags/england.png) no-repeat 0 0;
  width: 30px;
  height: 30px;
  background-size: 30px;
  margin-right: 7px;
  display: block;
  float: left;
}
.language-selector .language-selector__select-btn .language-selector__selected-language.russian::before {
  content: "";
  background: url(/public/images/flags/russia.png) no-repeat 0 0;
  width: 30px;
  height: 30px;
  background-size: 30px;
  margin-right: 7px;
  display: block;
  float: left;
}
.language-selector .language-selector__select-btn, .language-selector ul.options li {
  display: flex;
  cursor: pointer;
  align-items: center;
  text-transform: capitalize;
}

.selector__content {
  display: none;
  margin-top: 3px;
  padding: 0px 0px 10px 10px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  position: absolute;
  z-index: 10;
  left: 37px;
  transition: all 0.3s ease 0s;
  box-shadow: 2px 3px 3px rgb(128, 115, 117);
}
.selector__content ul.options {
  margin-top: 10px;
  max-height: 190px;
  overflow-y: auto;
  padding-right: 10px;
}
.selector__content ul.options li {
  line-height: 50px;
  border-radius: 5px;
  padding: 0 20px;
  font-size: 16px;
  cursor: pointer;
  text-transform: capitalize;
  color: rgb(27, 44, 0);
}
.selector__content ul.options li:hover {
  background: rgba(128, 115, 117, 0.5);
}
.selector__content ul.options li._selected {
  background: rgba(128, 115, 117, 0.1);
}
.selector__content ul.options::-webkit-scrollbar {
  width: 7px;
}
.selector__content ul.options::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}
.selector__content ul.options::-webkit-scrollbar-thumb {
  background: rgba(128, 115, 117, 0.5);
  border-radius: 25px;
}

.language-selector.mobile {
  display: block;
  position: static;
  width: 300px;
}

.language-selector.mobile .language-selector__select-btn {
  display: none;
}

.language-selector.mobile .selector__content {
  display: block;
  position: static;
}

.language-selector.active .selector__content {
  display: block;
}

.selector__content.active {
  display: block;
}

/**
 * Цвета градиентов
 */
.lds-spinner {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(128, 115, 117);
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.menu-logo-burger-container__burger,
.burger-container,
.burger-navigation,
.mobile-section {
  display: none;
}

body {
  background-color: rgb(27, 44, 0);
}

a.backref__link {
  text-decoration: none;
  color: rgba(128, 115, 117, 0.5);
}
a.backref__link:hover {
  color: rgb(128, 115, 117);
}

a.goto__link {
  text-decoration: none;
  color: rgb(77, 119, 1);
  padding-right: 10px;
}
a.goto__link:hover {
  color: rgb(27, 44, 0);
}

div#root .app-is-under-construction {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
}
div#root .app-is-under-construction .block {
  width: 280px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
div#root .app-is-under-construction .block .logo {
  padding-top: 0;
  padding-left: 0;
  margin: auto;
}
div#root .app-is-under-construction .block .under-construction-label {
  padding-top: 20px;
  margin: auto;
}
div#root .app-is-under-construction .block .under-construction-label span {
  font-family: "Roboto Slab";
  font-size: 20px;
  color: rgb(27, 44, 0);
  cursor: default;
}

body.lock {
  overflow: hidden;
}

.container {
  height: auto;
  background: url("/public/images/main-cat-q.jpg") no-repeat fixed center 0;
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.link {
  font-size: 22px;
  font-family: "Roboto";
  cursor: pointer;
  font-weight: 500;
  padding: 0 1em 0 0;
  color: rgba(128, 115, 117, 0.5);
  transition: all 0.3s ease 0s;
}
.link:hover {
  color: rgb(128, 115, 117);
}

.link.small {
  font-size: 16px;
  font-weight: 400;
}

.link.small.forgot-password {
  color: rgb(128, 115, 117);
}
.link.small.forgot-password:hover {
  color: rgb(27, 44, 0);
}

.link.active-link {
  color: rgb(27, 44, 0);
  cursor: default;
}

.pet_icon {
  width: 90px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px; /* Обеспечиваем небольшое пространство между элементами */
  transition: transform 0.5s ease; /* Сглаживание анимации перемещения */
}
.pet_icon .circle {
  margin-top: 10px;
  color: rgb(128, 115, 117);
  height: 60px;
  width: 60px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.pet_icon .circle .circle__plus {
  position: absolute;
  height: 60px;
  width: 60px;
}
.pet_icon .circle .circle__ellipsis {
  position: absolute;
  height: 60px;
  width: 60px;
}
.pet_icon .circle .pet-logo {
  height: 80px;
  width: 80px;
  margin-top: -10px;
  font-size: 38px;
  font-weight: 600;
  line-height: 80px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.95);
  background-color: rgb(255, 255, 255);
  color: rgb(128, 115, 117);
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.pet_icon .circle .pet-logo img {
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.pet_icon .circle:hover {
  border-color: rgb(27, 44, 0);
  color: rgb(27, 44, 0);
}

.header-container {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Выровнять элементы по вертикали */
  width: 100%; /* Занимает всю ширину */
}
.header-container .logo {
  padding-top: 20px;
  padding-left: 25px;
  transition: all 0.3s ease 0s;
}
.header-container .logo span {
  font-family: "Roboto Slab";
  font-size: 48px;
  color: rgb(255, 255, 255);
  background-color: rgb(244, 101, 0);
  cursor: default;
}
.header-container .logo span a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.header-container .top-menu {
  display: flex;
  padding-top: 20px;
  align-items: center;
}
.header-container .top-menu .top-menu__left {
  flex: 2 1 100px;
}
.header-container .top-menu .top-menu__left ul.top-menu__links {
  display: flex;
}
.header-container .top-menu .top-menu__left ul.top-menu__links li.top-menu__item {
  list-style: none;
  display: block;
  padding: 0 20px;
}
.header-container .top-menu .top-menu__left ul.top-menu__links li.top-menu__item a {
  text-transform: uppercase;
  font-size: 16px;
  position: relative;
  color: rgb(128, 115, 117);
}
.header-container .top-menu .top-menu__left ul.top-menu__links li.top-menu__item a span {
  display: none;
}
.header-container .top-menu .top-menu__left ul.top-menu__links li.top-menu__item a:hover {
  color: rgb(27, 44, 0);
  transition: all 0.3s ease 0s;
}
.header-container .top-menu .top-menu__left ul.top-menu__links li.top-menu__item a:hover span {
  top: 0;
  left: 40px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  background: rgba(255, 255, 255, 0.8);
  min-width: 50px;
  position: absolute;
  text-decoration: none;
}
.header-container .top-menu .top-menu__left ul.top-menu__links li.top-menu__item.active {
  color: rgb(27, 44, 0);
}
.header-container .top-menu .top-menu__pets-block {
  width: 100px;
  display: flex;
  position: relative;
}
.header-container .top-menu .top-menu__pets-block .top-menu__pets-additional_elements {
  display: flex;
  position: absolute;
  left: 100%; /* Позиционирование за пределами первого элемента */
  white-space: nowrap; /* Чтобы элементы располагались в одной строке */
  overflow: hidden; /* Скрываем дополнительные элементы до наведения */
  height: 80px;
}
.header-container .top-menu .top-menu__pets-block .top-menu__pets-additional_elements .pet_icon {
  width: 75px;
}
.header-container .top-menu .top-menu__pets-block:hover .top-menu__pets-additional_elements .pet_icon {
  transform: translateX(0%); /* При наведении двигаем дополнительные элементы слева направо */
}
.header-container .top-menu .top-menu__pets-additional_elements .pet_icon {
  transform: translateX(-250%); /* Исходное положение дополнительных элементов за пределами видимости */
  transition: transform 0.5s ease; /* Сглаживание анимации перемещения */
}
.header-container .top-menu .top-menu__right {
  flex: 2 1 100px;
  line-height: 60px;
  min-width: 100px;
}

.updated-element__wrapper {
  margin-bottom: 30px;
}
.updated-element__wrapper .updated-element__header {
  font-size: 24px;
  line-height: 50px;
  color: rgb(27, 44, 0);
  cursor: default;
  margin-left: 20px;
}
.updated-element__wrapper .updated-element__header span {
  font-weight: 500;
}
.updated-element__wrapper .updated-element__header .fa-arrow-left {
  width: 24px;
  height: 24px;
  color: rgb(27, 44, 0);
  cursor: pointer;
  margin-right: 20px;
}
.updated-element__wrapper .updated-element__header .fa-arrow-left:hover {
  color: rgb(244, 101, 0);
}
.updated-element__wrapper .updated-element__content {
  white-space: break-spaces;
  margin-left: 20px;
  line-height: 20px;
  font-size: 16px;
}

.left-menu-wrapper {
  flex: 0 1 80px;
}
.left-menu-wrapper .left-menu {
  margin-right: 20px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
}
.left-menu-wrapper .left-menu ul.left-menu__links li.left-menu__item {
  list-style: none;
  padding: 7px;
  margin: 0 auto;
  text-align: center;
}
.left-menu-wrapper .left-menu ul.left-menu__links li.left-menu__item a {
  text-transform: uppercase;
  font-size: 16px;
  position: relative;
}
.left-menu-wrapper .left-menu ul.left-menu__links li.left-menu__item a span {
  display: none;
}
.left-menu-wrapper .left-menu ul.left-menu__links li.left-menu__item a:hover {
  color: rgb(27, 44, 0);
  transition: all 0.3s ease 0s;
}
.left-menu-wrapper .left-menu ul.left-menu__links li.left-menu__item a:hover span {
  top: 0;
  left: 40px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  background: rgba(255, 255, 255, 0.8);
  min-width: 50px;
  position: absolute;
  text-decoration: none;
}
.left-menu-wrapper .left-menu ul.left-menu__links li.left-menu__item.active {
  color: rgb(27, 44, 0);
}
.left-menu-wrapper .left-menu a {
  color: rgb(128, 115, 117);
  font-weight: bold;
}
.left-menu-wrapper .left-menu a:hover {
  color: rgb(77, 119, 1);
}

.login-section {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  padding-right: 25px;
  padding-top: 20px;
  flex-grow: 0;
  flex-shrink: 1;
}

.login-button-with-auth-user-menu {
  position: relative;
}

.orange-btn {
  width: 136px;
  min-height: 50px;
  border-radius: 10px;
  background-color: rgb(244, 101, 0);
  color: rgb(255, 255, 255);
  cursor: pointer;
  outline: 2px solid rgb(244, 101, 0);
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
  display: flex;
  align-items: center;
}
.orange-btn span.orange-btn__label {
  font-size: 16px;
  display: inline-block;
  line-height: 24px;
  margin: 0 auto;
}
.orange-btn:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(244, 101, 0);
}

.orange-btn.reverse {
  background-color: rgb(255, 255, 255);
  color: rgb(244, 101, 0);
}
.orange-btn.reverse:hover {
  background-color: rgb(244, 101, 0);
  color: rgb(255, 255, 255);
}

.light-brown-btn {
  width: 136px;
  line-height: 50px;
  border-radius: 10px;
  background-color: rgb(128, 115, 117);
  color: rgb(255, 255, 255);
  cursor: pointer;
  outline: 2px solid rgb(128, 115, 117);
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
.light-brown-btn span.light-brown-btn__label {
  font-size: 16px;
}
.light-brown-btn:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(128, 115, 117);
}

.light-brown-btn.reverse {
  background-color: rgb(255, 255, 255);
  color: rgb(128, 115, 117);
}
.light-brown-btn.reverse:hover {
  background-color: rgb(128, 115, 117);
  color: rgb(255, 255, 255);
}

.orange-btn.center {
  margin: 0 auto;
}

.orange-btn.center.disabled {
  margin: 0 auto;
  background-color: rgba(128, 115, 117, 0.5);
  color: rgb(255, 255, 255);
  outline: 2px solid rgba(128, 115, 117, 0.5);
}

.remove-btn {
  width: 100px;
  background: url(/public/images/icons/trash.png) no-repeat center;
  position: relative;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.light-green-btn {
  width: 136px;
  line-height: 50px;
  border-radius: 10px;
  background-color: rgb(77, 119, 1);
  color: rgb(255, 255, 255);
  cursor: pointer;
  outline: 2px solid rgb(77, 119, 1);
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
.light-green-btn span.light-green-btn__label {
  font-size: 16px;
}
.light-green-btn:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(77, 119, 1);
}

.dark-green-btn {
  width: 136px;
  line-height: 50px;
  border-radius: 10px;
  background-color: rgb(27, 44, 0);
  color: rgb(255, 255, 255);
  cursor: pointer;
  outline: 2px solid rgb(27, 44, 0);
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
.dark-green-btn span.dark-green-btn__label {
  font-size: 16px;
}
.dark-green-btn:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(27, 44, 0);
}

.dark-green-btn.center {
  margin: 0 auto 20px;
}

.orange-btn.promo {
  position: static;
  margin: 30px auto auto;
}

.language-container {
  margin: 0 auto;
  max-width: 1400px;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
}

.content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 120px 25px 50px;
  min-height: 90vh;
}

.content-flex-wrapper {
  display: flex;
}

.basic-information-block {
  flex: 2 1 970px;
  padding: 15px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.95);
  max-width: 1400px;
  position: relative;
}

.panel-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 120px 25px 50px;
  min-height: 90vh;
}
.panel-content .basic-information-block {
  display: flex;
}
.panel-content .admin-panel__menu {
  width: 220px;
  display: flex;
}
.panel-content .admin-panel__menu .admin-panel__menu-items-wrapper {
  background: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
}
.panel-content .admin-panel__menu .admin-panel__menu-items-wrapper ul.admin-panel__menu-items {
  overflow-y: auto;
}
.panel-content .admin-panel__menu .admin-panel__menu-items-wrapper ul.admin-panel__menu-items li {
  border-radius: 10px;
  line-height: 50px;
  font-size: 16px;
  cursor: pointer;
  text-transform: capitalize;
  width: 220px;
  padding-left: 10px;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  color: rgb(128, 115, 117);
}
.panel-content .admin-panel__menu .admin-panel__menu-items-wrapper ul.admin-panel__menu-items li:hover {
  background: rgba(128, 115, 117, 0.1);
}
.panel-content .admin-panel__menu .admin-panel__menu-items-wrapper ul.admin-panel__menu-items li._selected {
  background: rgba(128, 115, 117, 0.5);
  color: rgb(27, 44, 0);
  font-weight: 400;
}
.panel-content .admin-panel__content {
  width: 100%;
}
.panel-content .admin-panel__content .admin-panel__content_header {
  margin-bottom: 30px;
  padding-left: 50px;
  text-align: center;
}
.panel-content .admin-panel__content .admin-panel__content_header h1 {
  font-size: 24px;
  font-weight: bold;
  color: rgb(27, 44, 0);
}
.panel-content .admin-panel__content .admin-panel__content_info {
  padding-left: 50px;
}

.backward-link-and-add-dict-icon__wrapper {
  display: flex;
  justify-content: space-between;
}
.backward-link-and-add-dict-icon__wrapper .backward-link {
  display: flex;
}
.backward-link-and-add-dict-icon__wrapper .add-dict-icon {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.backward-link-and-add-dict-icon__wrapper.hidden {
  display: none;
}

.dictionary-list .dictionary-item {
  line-height: 30px;
  font-size: 16px;
  color: rgb(128, 115, 117);
}
.dictionary-list .dictionary-item:hover {
  cursor: pointer;
  text-decoration: underline;
}

.information-block-wrapper {
  width: 100%;
}

.information-block-content.no-borders:not(:last-child) {
  margin-bottom: 0;
}

.information-block-content.no-borders {
  border: none;
  margin-bottom: 0;
}
.information-block-content.no-borders .information-block-content__body .information-block-content__body-item {
  min-height: 30px;
  cursor: default;
  border-bottom: none;
  border-radius: 10px;
  margin-bottom: 7px;
}
.information-block-content.no-borders .information-block-content__body .information-block-content__body-item .item-content {
  color: rgb(128, 115, 117);
  font-size: 16px;
}
.information-block-content.no-borders .information-block-content__body .information-block-content__body-item .item-action {
  display: none;
}
.information-block-content.no-borders .information-block-content__body .information-block-content__body-item:hover {
  background-color: rgba(128, 115, 117, 0.1);
}

.dictionary-element-header__wrapper {
  margin-bottom: 10px;
}
.dictionary-element-header__wrapper .dictionary-element-header {
  font-size: 32px;
  color: rgb(128, 115, 117);
  cursor: default;
  margin-left: 20px;
}
.dictionary-element-header__wrapper .dictionary-element-header .dictionary-title {
  margin-bottom: 5px;
}
.dictionary-element-header__wrapper .dictionary-element-header .dictionary-title span {
  font-weight: normal;
  color: rgba(128, 115, 117, 0.5);
  font-size: 16px;
  text-transform: uppercase;
}
.dictionary-element-header__wrapper .dictionary-element-header .dictionary-title span a {
  color: rgba(128, 115, 117, 0.5);
}
.dictionary-element-header__wrapper .dictionary-element-header .dictionary-title span a:hover {
  color: rgb(128, 115, 117);
}
.dictionary-element-header__wrapper .dictionary-element-header span {
  font-weight: 500;
}
.dictionary-element-header__wrapper .dictionary-element-header span.by-center {
  display: flex;
  justify-content: center; /* Горизонтальное выравнивание по центру */
  align-items: center; /* Вертикальное выравнивание по центру */
  width: 100%; /* Занимает всю ширину родителя */
}
.dictionary-element-header__wrapper .dictionary-element-header .fa-arrow-left {
  width: 32px;
  height: 32px;
  color: rgba(128, 115, 117, 0.5);
  cursor: pointer;
  margin-right: 20px;
}
.dictionary-element-header__wrapper .dictionary-element-header .fa-arrow-left:hover {
  color: rgb(128, 115, 117);
}
.dictionary-element-header__wrapper .dictionary-element-header .fa-dictionary-edit {
  width: 24px;
  height: 24px;
  line-height: 50px;
  color: rgba(128, 115, 117, 0.5);
  cursor: pointer;
  margin-left: 15px;
  margin-bottom: 5px;
}
.dictionary-element-header__wrapper .dictionary-element-header .fa-dictionary-edit:hover {
  color: rgb(128, 115, 117);
}

.information-block-content {
  border-radius: 20px;
  border: 1px solid rgba(128, 115, 117, 0.5);
  padding: 20px;
}
.information-block-content .information-block-content__header {
  font-size: 18px;
  color: rgb(27, 44, 0);
  margin-bottom: 30px;
}
.information-block-content .information-block-content__body .information-block-content__body-item {
  display: flex;
  width: 100%;
  transition: all 0.3s ease 0s;
  border-bottom: 1px solid rgba(128, 115, 117, 0.5);
  min-height: 40px;
  align-items: center;
  line-height: 22px;
  cursor: pointer;
}
.information-block-content .information-block-content__body .information-block-content__body-item .item-title {
  display: flex;
  align-items: center;
  min-width: 210px;
  max-width: 210px;
  text-transform: uppercase;
  font-size: 12px;
  color: rgba(128, 115, 117, 0.5);
  flex-grow: 2;
  padding-left: 5px;
}
.information-block-content .information-block-content__body .information-block-content__body-item .item-title span {
  width: 190px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.information-block-content .information-block-content__body .information-block-content__body-item .item-content {
  font-size: 16px;
  color: rgb(27, 44, 0);
  white-space: pre-line;
  flex-grow: 7;
}
.information-block-content .information-block-content__body .information-block-content__body-item .item-action {
  flex-grow: 1;
  text-align: end;
  padding-right: 10px;
}
.information-block-content .information-block-content__body .information-block-content__body-item .item-action .fa-angle-right {
  color: rgba(128, 115, 117, 0.5);
}
.information-block-content .information-block-content__body .information-block-content__body-item:hover {
  background-color: #f1f1f1;
}
.information-block-content .information-block-content__body .information-block-content__body-item.disabled .item-content {
  font-size: 16px;
  color: rgba(128, 115, 117, 0.5);
  flex-grow: 7;
}
.information-block-content .information-block-content__body .information-block-content__body-item.disabled .item-action {
  display: none;
}
.information-block-content .information-block-content__body .information-block-content__body-item.disabled:hover {
  background-color: rgb(255, 255, 255);
  cursor: default;
}
.information-block-content .information-block-content__body .add-element__content {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 40px;
  font-weight: 500;
  color: rgb(128, 115, 117);
  padding-left: 10px;
  transition: all 0.3s ease 0s;
}
.information-block-content .information-block-content__body .add-element__content .add-element__content-plus {
  padding-right: 8px;
  cursor: pointer;
}
.information-block-content .information-block-content__body .add-element__content span {
  cursor: pointer;
}
.information-block-content .information-block-content__body .add-element__content:hover {
  color: rgb(27, 44, 0);
}
.information-block-content .information-block-content__body > .add-element__content {
  margin-top: 20px;
}
.information-block-content .information-block-content__body > .add-element__content ~ .add-element__content {
  margin-top: 0;
}

.information-block-content:not(:last-child) {
  margin-bottom: 30px;
}

.section-title {
  width: fit-content;
  margin: 0 auto;
  text-align: center;
}
.section-title .greetings {
  padding: 20px 0 30px 0;
  cursor: default;
}
.section-title .greetings h1 {
  font-size: 24px;
  font-weight: bold;
  color: rgb(27, 44, 0);
  /*color: $almostWhiteFilled;
  background-color: $darkGreenFilled;*/
}
.section-title .greetings._editable {
  line-height: 38px;
}
.section-title .greetings._editable h1 {
  display: inline;
  padding-right: 10px;
}
.section-title .greetings._editable a {
  cursor: pointer;
}
.section-title .greetings._editable a img {
  height: 38px;
  width: 38px;
}

.section-title.breed {
  position: absolute;
  top: 40px;
  left: 20px;
  width: auto;
  background: rgba(128, 115, 117, 0.1);
  letter-spacing: 0.05rem;
}
.section-title.breed .greetings {
  padding: 10px 0;
}
.section-title.breed .greetings h1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-weight: 500;
  background: none;
}

.section-title.breed.without-image {
  position: static;
  width: fit-content;
  margin: 40px auto;
  text-align: center;
  background: rgba(128, 115, 117, 0.1);
  letter-spacing: 0.05rem;
}
.section-title.breed.without-image .greetings {
  padding: 10px 0;
}
.section-title.breed.without-image .greetings h1 {
  font-size: 32px;
  background: none;
  color: rgb(27, 44, 0);
}

.breed-photo__wrap-around {
  position: static;
  height: 400px;
}
.breed-photo__wrap-around .breed-photo {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  height: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
}
.breed-photo__wrap-around .breed-photo img {
  border-radius: 10px 10px 0 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.breed-photo__wrapper {
  width: 100%;
  height: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.breed-photo__wrapper .breed-photo__add-button {
  width: 100%;
  height: 100%;
  border: 1px dashed rgb(128, 115, 117);
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  transition: all 0.3s ease 0s;
  color: rgba(128, 115, 117, 0.5);
}
.breed-photo__wrapper .breed-photo__add-button:hover {
  border: 1px dashed rgb(27, 44, 0);
  color: rgb(27, 44, 0);
}
.breed-photo__wrapper .breed-photo__add-button span {
  line-height: 400px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.03em;
  transition: all 0.3s ease 0s;
}
.breed-photo__wrapper .image-item {
  position: relative;
  width: 100%;
  height: 100%;
}
.breed-photo__wrapper .plain-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.fa-circle-info {
  padding-left: 15px;
  color: rgba(128, 115, 117, 0.3);
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.fa-circle-info:hover {
  color: rgb(128, 115, 117);
}

.circle-photo__wrapper {
  width: 100%;
  height: 300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.circle-photo__wrapper .circle-photo__add-button {
  width: 300px;
  height: 300px;
  line-height: 300px;
  border-radius: 50%;
  border: 1px solid rgba(128, 115, 117, 0.5);
  color: rgba(128, 115, 117, 0.5);
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.circle-photo__wrapper .circle-photo__add-button img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.circle-photo__wrapper .circle-photo__add-button .fa-camera {
  width: 150px;
  height: 150px;
  vertical-align: middle;
}
.circle-photo__wrapper .circle-photo__add-button:hover {
  color: rgb(128, 115, 117);
  border-color: rgb(128, 115, 117);
}
.circle-photo__wrapper .circle-photo__add-button.static {
  cursor: default;
}
.circle-photo__wrapper .circle-photo__add-button.static:hover {
  color: rgba(128, 115, 117, 0.5);
  border-color: rgba(128, 115, 117, 0.5);
}
.circle-photo__wrapper .circle-image-item {
  position: relative;
  width: 300px;
  height: 300px;
}
.circle-photo__wrapper .circle-image-item .fa-pencil {
  color: rgba(128, 115, 117, 0.5);
  position: absolute;
  right: 0;
  width: 25px;
  height: 25px;
  margin-top: 5px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  z-index: 5;
}
.circle-photo__wrapper .circle-image-item .fa-pencil:hover {
  color: rgb(128, 115, 117);
}
.circle-photo__wrapper .img-circle {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
}

.gender-params__wrapper {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 160px;
}
.gender-params__wrapper .gender-params__header {
  line-height: 34px;
  display: flex;
  cursor: pointer;
}
.gender-params__wrapper .gender-params__header .gender-params__header-icon {
  width: 50%;
  text-align: center;
  background-color: rgba(128, 115, 117, 0.1);
  transition: all 0.3s ease 0s;
}
.gender-params__wrapper .gender-params__header .gender-params__header-icon:hover {
  background-color: rgba(128, 115, 117, 0.3);
}
.gender-params__wrapper .gender-params__header .gender-params__header-icon.active {
  background-color: rgba(128, 115, 117, 0.3);
  cursor: default;
}
.gender-params__wrapper .gender-params__header .gender-params__header-icon.male {
  border-radius: 10px 0 0 0;
  color: rgba(0, 123, 255, 0.8);
}
.gender-params__wrapper .gender-params__header .gender-params__header-icon.female {
  border-radius: 0 10px 0 0;
  color: rgba(255, 105, 180, 0.8);
}
.gender-params__wrapper .gender-params__params-section .gender-params__param {
  background-color: rgba(128, 115, 117, 0.3);
  display: flex;
  line-height: 36px;
}
.gender-params__wrapper .gender-params__params-section .gender-params__param .gender-params__param-icon {
  width: 40%;
  max-height: 40px;
  display: flex;
  align-items: center; /* Центрирование по вертикали */
  justify-content: center; /* Центрирование по горизонтали */
}
.gender-params__wrapper .gender-params__params-section .gender-params__param .gender-params__param-icon img {
  max-height: 26px;
  width: auto; /* Сохранение пропорций изображения */
  object-fit: contain; /* Вписывание картинки в контейнер */
}
.gender-params__wrapper .gender-params__params-section .gender-params__param .gender-params__param-data {
  width: 60%;
  display: flex;
  align-items: center; /* Центрирование по вертикали */
  justify-content: left; /* Центрирование по горизонтали (если нужно) */
}
.gender-params__wrapper .gender-params__params-section .gender-params__param .gender-params__param-data span {
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
}
.gender-params__wrapper .gender-params__params-section .gender-params__param:last-child {
  border-radius: 0 0 10px 10px;
}

.breed-characteristics {
  position: absolute;
  right: 20px;
  top: 20px;
}
.breed-characteristics .breed-characteristics__item {
  background: rgba(128, 115, 117, 0.1);
  margin-bottom: 20px;
  max-width: 150px;
}
.breed-characteristics .breed-characteristics__item .breed-characteristics__item-header {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3em;
  color: rgb(255, 255, 255);
}
.breed-characteristics .breed-characteristics__item .breed-characteristics__item-body {
  font-size: 16px;
  line-height: 1.3em;
  color: rgb(255, 255, 255);
}

.breed-facts {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 20px 10px;
  background-color: rgba(128, 115, 117, 0.1);
  border-radius: 10px;
}
.breed-facts .breed-facts__fact {
  max-width: 600px;
  margin: 20px auto;
  line-height: 1.33em;
  display: flex;
}
.breed-facts .breed-facts__fact p {
  font-size: 18px;
  font-weight: 400;
  display: inline-flex;
  vertical-align: bottom;
  align-items: center;
}
.breed-facts .breed-facts__fact p:before {
  content: "—";
  font-size: 24px;
  font-weight: bold;
  line-height: 1em;
  margin-right: 10px;
}

.breed-info-block__wrapper {
  display: flex;
  align-items: center; /* Центрирование по вертикали */
  justify-content: center; /* Центрирование по горизонтали */
  flex-direction: column;
}
.breed-info-block__wrapper .breed-info-block__header {
  display: flex;
  justify-content: center; /* Центрирование по горизонтали */
  margin-bottom: 15px;
}
.breed-info-block__wrapper .breed-info-block__header span {
  font-size: 32px;
  font-weight: 500;
  color: rgb(128, 115, 117);
  line-height: 40px;
}
.breed-info-block__wrapper .breed-info-block__aggregated-mark {
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center; /* Центрирование по вертикали */
  justify-content: center; /* Центрирование по горизонтали */
  flex-direction: column;
  margin-bottom: 15px;
}
.breed-info-block__wrapper .breed-info-block__aggregated-mark .aggregated-mark__mark-comment {
  margin-top: 15px;
}
.breed-info-block__wrapper .breed-info-block__aggregated-mark .aggregated-mark__mark-comment span {
  font-size: 14px;
  line-height: 1.33em;
  word-break: break-word;
  text-transform: uppercase;
  color: rgb(128, 115, 117);
}
.breed-info-block__wrapper .breed-info__section {
  display: flex;
  flex-direction: column;
}
.breed-info-block__wrapper .breed-info__section .breed-info__section-title {
  justify-content: start; /* Центрирование по горизонтали */
  margin-bottom: 10px;
  padding-left: 23px;
}
.breed-info-block__wrapper .breed-info__section .breed-info__section-title span {
  font-size: 22px;
  font-weight: 400;
  color: rgb(128, 115, 117);
  line-height: 26px;
}

.breed-info__section-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.breed-info__section-body.visible {
  max-height: 1000px;
}

.arrow {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin: 0 1rem;
}
.arrow span {
  top: 0.7rem;
  position: absolute;
  width: 0.75rem;
  height: 0.1rem;
  background-color: rgb(128, 115, 117);
  display: inline-block;
  transition: all 0.2s ease;
}
.arrow span:first-of-type {
  left: 0;
  transform: rotate(45deg);
}
.arrow span:last-of-type {
  right: 0;
  transform: rotate(-45deg);
}
.arrow.active span {
  transition: all 0.2s ease;
}
.arrow.active span:first-of-type {
  transform: rotate(-45deg);
}
.arrow.active span:last-of-type {
  transform: rotate(45deg);
}

.read-more-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 15px;
}
.read-more-container .read-more-text {
  color: rgba(128, 115, 117, 0.3);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease 0s;
}
.read-more-container .read-more-text:hover {
  color: rgb(128, 115, 117);
}

.progress-bar.wide {
  height: 50px;
}

.breed-info-block__preview {
  width: 100%;
  max-width: 1200px;
}
.breed-info-block__preview .breed-primary-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.breed-info-block__preview .breed-primary-info span {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  color: rgb(128, 115, 117);
}

.breed-info-block__details {
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  max-height: 0;
}
.breed-info-block__details .details-label {
  margin-top: 30px;
  margin-bottom: -5px;
}
.breed-info-block__details .details-label span {
  padding-left: 15px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(128, 115, 117);
}
.breed-info-block__details .breed-info-block__params {
  max-width: 1000px;
  display: flex;
  justify-content: center;
  margin: 20px auto 20px auto;
}
.breed-info-block__details .breed-info-block__params .breed-info-block__params-interlayer {
  display: flex;
  flex-wrap: wrap;
}
.breed-info-block__details .breed-info-block__params .breed-info-block__params-interlayer .breed-info-block__params-param {
  width: 300px;
  margin-left: 50px; /* Расстояние между элементами */
  margin-bottom: 25px;
}
.breed-info-block__details .breed-info-block__params .breed-info-block__params-interlayer .breed-info-block__params-param .param-label span {
  padding-left: 15px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  color: rgb(128, 115, 117);
}
.breed-info-block__details .breed-info-block__params .breed-info-block__params-interlayer .breed-info-block__params-param .progress-bar .progress span {
  font-size: 12px;
  padding-right: 20px;
}
.breed-info-block__details .breed-info-block__params .breed-info-block__params-interlayer .breed-info-block__params-param:nth-child(3n+1) {
  margin-left: 0; /* Обнуляем отступ у первого элемента в каждой строке */
}
.breed-info-block__details .details-summary {
  text-align: justify;
  margin-bottom: 20px;
}
.breed-info-block__details .details-summary span {
  line-height: 20px;
  color: rgb(128, 115, 117);
}

.breed-info-block__details.expanded {
  max-height: 1200px;
  font-size: 16px;
}

.progress-bar.thin {
  height: 35px;
  max-width: 350px;
}

.progress-bar {
  width: 100%;
  height: 40px;
  background: linear-gradient(90deg, rgba(128, 115, 117, 0.3) 0%, rgba(128, 115, 117, 0.1) 100%); /* Back to Base at 100% */
  border-radius: 20px;
  display: flex;
  align-items: center;
}
.progress-bar .progress {
  height: 105%;
  max-width: fit-content; /* Максимальная ширина по содержимому */
  border-radius: 25px; /* Закругленные концы только слева, чтобы не перекрывать общий закругленный правый конец */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}
.progress-bar .progress span {
  font-size: 16px;
  font-weight: 600;
  padding-left: 20px;
  text-transform: uppercase;
}
.progress-bar .progress.green {
  min-width: 25%;
  background: linear-gradient(90deg, rgba(144, 238, 144, 0.8) 0%, rgba(80, 200, 120, 0.8) 25%, rgba(34, 139, 34, 0.8) 100%);
}
.progress-bar .progress.green span {
  color: rgba(34, 139, 34, 0.8);
}
.progress-bar .progress.yellow {
  min-width: 50%;
  background: linear-gradient(90deg, rgba(255, 255, 224, 0.8) 0%, rgba(255, 223, 0, 0.8) 33%, rgba(255, 191, 0, 0.8) 66%, rgba(255, 165, 0, 0.8) 100%);
}
.progress-bar .progress.yellow span {
  color: rgba(255, 165, 0, 0.8);
}
.progress-bar .progress.orange {
  min-width: 75%;
  background: linear-gradient(90deg, rgba(255, 204, 153, 0.8) 0%, rgba(255, 128, 0, 0.8) 33%, rgba(255, 140, 0, 0.8) 66%, rgba(204, 85, 0, 0.8) 100%);
}
.progress-bar .progress.orange span {
  color: rgba(204, 85, 0, 0.8);
}
.progress-bar .progress.red {
  min-width: 100%;
  background: linear-gradient(90deg, rgba(255, 99, 71, 0.8) 0%, rgba(204, 0, 0, 0.8) 33%, rgba(139, 0, 0, 0.8) 66%, rgba(128, 0, 32, 0.8) 100%);
}
.progress-bar .progress.red span {
  color: rgba(128, 0, 32, 0.8);
}

.article-with-title {
  margin: 20px 30px 50px;
  transition: all 0.3s ease 0s;
}
.article-with-title .article-with-title__title span {
  font-size: 24px;
  color: rgb(27, 44, 0);
  font-weight: bold;
  line-height: 2em;
}
.article-with-title .article-with-title__title.source-header span {
  font-size: 16px;
}
.article-with-title .article-with-title__text {
  white-space: pre-line;
}
.article-with-title .article-with-title__text p {
  font-size: 16px;
  line-height: 1.33em;
  letter-spacing: 0.02rem;
  word-break: break-word;
  text-align: justify;
}

.article-with-title.margin-top {
  margin-top: 400px;
}

.article-with-title__text.sources p {
  cursor: default;
  font-size: 12px;
}

.pads_wrapper {
  width: 100%;
}

.about-milkins {
  font-size: 22px;
  color: rgb(27, 44, 0);
  background-color: rgba(255, 255, 255, 0.95);
  padding: 15px;
  border-radius: 20px;
  width: 440px;
  cursor: default;
}

.milkins-telegram-link__wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.milkins-telegram-link__wrapper .telegram-logo {
  height: 32px;
  width: 32px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
.milkins-telegram-link__wrapper span {
  cursor: pointer;
  display: inline-block;
  padding-left: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  color: rgb(128, 115, 117);
}
.milkins-telegram-link__wrapper span:hover {
  color: rgb(27, 44, 0);
}

.content__user-group-login {
  width: 205px;
  padding: 15px 15px 45px;
  margin-top: 60px;
  background-color: rgba(128, 115, 117, 0.5);
  color: rgb(244, 240, 244);
  font-size: 20px;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
  letter-spacing: 0.05em;
  transition: all 0.3s ease 0s;
}
.content__user-group-login:hover {
  background-color: rgb(128, 115, 117);
}
.content__user-group-login:after {
  content: "";
  background: url(/public/images/icons/arrow_next_icon__light.png) no-repeat 0 0;
  background-size: 35px;
  position: absolute;
  margin-left: 145px;
  margin-top: 5px;
  width: 35px;
  height: 35px;
  pointer-events: none;
  display: block;
}

.section-with-links {
  margin-top: 60px;
}
.section-with-links span.section-title {
  font-size: 28px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  background-color: rgb(244, 101, 0);
  cursor: default;
}

.promo-section {
  padding: 25px;
  margin: 20px 0;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: fit-content;
  transition: all 0.3s ease 0s;
}
.promo-section .link-to-popup__text {
  font-size: 22px;
  font-weight: bold;
  color: rgb(27, 44, 0);
}
.promo-section div.link-to-popup__arrow {
  background: url(/public/images/icons/arrow_next_icon__dark.png) no-repeat 0 0;
  background-size: 35px;
  margin-left: 15px;
  margin-top: -5px;
  width: 35px;
  height: 35px;
  min-width: 35px;
  pointer-events: none;
  display: inline-block;
}
.promo-section:hover {
  background-color: rgb(255, 255, 255);
}

footer {
  max-width: 1400px;
  margin: 0 auto;
  /*position:absolute;
  bottom: 0;
  left:0;
  right: 0;*/
}

.footer-gradient {
  height: 150px;
  background-image: linear-gradient(to bottom, rgba(27, 44, 0, 0), rgb(27, 44, 0));
}

.copyright-info {
  justify-content: space-between;
  background-color: rgb(27, 44, 0);
  height: 100px;
  padding: 0 50px;
  display: flex;
  align-items: center;
}
.copyright-info div {
  font-size: 16px;
  line-height: 1.15;
  letter-spacing: 0.02em;
  color: rgb(244, 240, 244);
  cursor: default;
}
.copyright-info div span {
  color: rgba(255, 255, 255, 0.95);
}
.copyright-info div a.copyright-info__link {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.95);
}
.copyright-info div a.copyright-info__link:hover {
  color: rgb(255, 255, 255);
}

div.close_btn {
  display: block;
  right: 15px;
  margin-top: -10px;
  position: absolute;
  width: 30px;
  height: 20px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  z-index: 5;
}
div.close_btn span {
  position: absolute;
  background-color: rgb(27, 44, 0);
  left: 0;
  width: 100%;
  height: 2px;
  top: 9px;
}
div.close_btn:before, div.close_btn:after {
  content: "";
  background-color: rgba(128, 115, 117, 0.5);
  position: absolute;
  width: 100%;
  height: 2px;
  margin-top: 18px;
  transition: all 0.3s ease 0s;
}
div.close_btn:before {
  top: 0;
}
div.close_btn:after {
  bottom: 0;
}
div.close_btn:hover:before, div.close_btn:hover:after {
  background-color: rgb(128, 115, 117);
}

.close_btn.close-image {
  right: 15px;
  margin-top: 5px;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.4s ease 0s;
}
.popup .popup__body {
  min-height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup .popup__body .popup__content {
  background-color: rgb(255, 255, 255);
  color: rgb(27, 44, 0);
  max-width: 800px;
  padding: 30px;
  position: relative;
  border-radius: 20px;
  transition: all 0.5s ease 0s;
}
.popup .popup__body .popup__content .popup__title {
  font-size: 22px;
  margin: 0 0 1em 0;
}
.popup .popup__body .popup__content .popup__text {
  padding-top: 15px;
  font-size: 16px;
}
.popup .popup__content {
  transform: translate(0px, -100%);
  opacity: 0;
}

.success-message {
  line-height: 30px;
  width: 100%;
  white-space: pre-wrap;
  text-align: center;
}
.success-message span {
  color: rgb(77, 119, 1);
}

.success-message.black span {
  color: black;
}

.close_btn:before {
  transform: rotate(45deg);
  top: 9px;
  background-color: rgb(255, 255, 255);
}

.close_btn:after {
  transform: rotate(-45deg);
  bottom: 9px;
  background-color: rgb(255, 255, 255);
}

.close_btn span {
  transform: scale(0);
}

.popup.open {
  opacity: 1;
  visibility: visible;
}

.popup.open .popup__content {
  transform: translate(0px, 0px);
  opacity: 1;
}

.simple-link {
  list-style: none;
  font-size: 16px;
  font-weight: 400;
  color: rgba(128, 115, 117, 0.5);
  transition: all 0.3s ease 0s;
}
.simple-link:hover {
  color: rgb(128, 115, 117);
}

.breadcrumb-wrapper {
  position: absolute;
  top: 20px;
  left: 20px;
}
.breadcrumb-wrapper .breadcrumb {
  line-height: 22px;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-items {
  display: flex;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-items .breadcrumb-item {
  list-style: none;
  font-size: 14px;
  margin-right: 13px;
  font-weight: 500;
  position: relative;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-items .breadcrumb-item:before {
  content: "/";
  position: absolute;
  right: -10px;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-items .breadcrumb-item:last-child:before {
  content: "";
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-items .breadcrumb-item a {
  color: rgb(128, 115, 117);
  transition: all 0.3s ease 0s;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-items .breadcrumb-item a:hover {
  color: rgb(27, 44, 0);
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-items .breadcrumb-item.active {
  color: rgb(77, 119, 1);
  cursor: default;
}

.table-line {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 50px;
  border-bottom: 1px solid rgb(128, 115, 117);
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease 0s;
}
.table-line:hover {
  background-color: rgba(128, 115, 117, 0.1);
}
.table-line .table-line__title {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px;
}
.table-line .table-line__title span {
  text-transform: uppercase;
  font-size: 12px;
  color: rgb(128, 115, 117);
}
.table-line .table-line__value {
  width: 100%;
  padding: 10px 25px 10px 10px;
  overflow: hidden;
}
.table-line:last-child {
  border-bottom: none;
}

.table-line.with-arrow:after {
  content: "";
  background: url(/public/images/icons/right_arrow.png) no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 7px;
}

.table-line._editable:hover {
  cursor: pointer;
}

.admin-panel.total-count-of-elements {
  padding-left: 10px;
  cursor: default;
}
.admin-panel.total-count-of-elements span {
  font-weight: 600;
  color: rgb(128, 115, 117);
}

.simple-table {
  margin-top: 20px;
  background-color: rgba(128, 115, 117, 0.1);
  border-radius: 10px;
}
.simple-table .table-line.table-title {
  border-bottom: none;
}
.simple-table .table-line__cell {
  text-align: center;
  color: rgb(27, 44, 0);
  height: 100%;
}
.simple-table .table-line__cell span {
  display: inline-block;
}
.simple-table .cell-100 {
  width: 100%;
}
.simple-table .cell-90 {
  width: 90%;
}
.simple-table .cell-75 {
  width: 75%;
}
.simple-table .cell-65 {
  width: 65%;
}
.simple-table .cell-50 {
  width: 50%;
}
.simple-table .cell-40 {
  width: 40%;
}
.simple-table .cell-25 {
  width: 25%;
}
.simple-table .cell-20 {
  width: 20%;
}
.simple-table .cell-15 {
  width: 15%;
}
.simple-table .cell-10 {
  width: 10%;
}

.table-line__cell.small-text {
  font-size: 14px;
}

.table-line__cell.cell-25.pet-logo-in-cell {
  width: 130px;
  height: 100px;
  display: flex;
  align-items: center;
}

.table-line__cell.cell-75.pet-details-in-cell {
  width: 250px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.simple-table .pet_icon .circle {
  margin-top: 0;
}

.simple-table.no-result .table-line {
  cursor: default;
}

span.table-line__description {
  text-transform: uppercase;
  font-size: 12px;
  color: rgb(128, 115, 117);
}

.type-selector {
  margin: 0 auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.type-selector .selector-label {
  font-family: "Roboto", sans-serif;
  color: rgb(128, 115, 117);
  font-size: 18px;
  margin-bottom: 10px;
}
.type-selector .selector-options {
  display: flex;
}
.type-selector .selector-options .type-selector__item {
  border: 1px solid rgb(128, 115, 117);
  border-radius: 10px;
  color: rgb(27, 44, 0);
  width: 50%;
  height: 50px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  margin-right: 3px;
}
.type-selector .selector-options .type-selector__item span {
  line-height: 50px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}
.type-selector .selector-options .type-selector__item:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(27, 44, 0);
  border: 1px solid rgb(27, 44, 0);
}
.type-selector .selector-options .type-selector__item:last-child {
  margin-right: 0;
}
.type-selector .selector-options .type-selector__item.active {
  color: rgb(255, 255, 255);
  background-color: rgb(27, 44, 0);
  border: 1px solid rgb(27, 44, 0);
}
.type-selector .selector-options .type-selector__item.disabled {
  color: rgba(128, 115, 117, 0.5);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(128, 115, 117, 0.5);
  cursor: default;
}
.type-selector .selector-options .type-selector__item.active.disabled {
  color: rgb(255, 255, 255);
  background-color: rgba(128, 115, 117, 0.5);
  border: 1px solid rgba(128, 115, 117, 0.5);
  cursor: default;
}

.translation-lang-selector {
  margin: 0 auto 20px;
  max-width: 500px;
  display: flex;
  justify-content: center;
}
.translation-lang-selector .translation-lang-selector__item {
  border: 1px solid rgb(128, 115, 117);
  border-radius: 10px;
  color: rgb(27, 44, 0);
  width: 70px;
  height: 50px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  margin-right: 3px;
}
.translation-lang-selector .translation-lang-selector__item span {
  line-height: 50px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}
.translation-lang-selector .translation-lang-selector__item:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(27, 44, 0);
  border: 1px solid rgb(27, 44, 0);
}
.translation-lang-selector .translation-lang-selector__item:last-child {
  margin-right: 0;
}
.translation-lang-selector .translation-lang-selector__item.active {
  color: rgb(255, 255, 255);
  background-color: rgb(27, 44, 0);
  border: 1px solid rgb(27, 44, 0);
}

.filter-parameters {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.filter-parameters .filter-parameters__item {
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}
.filter-parameters .filter-parameters__item span {
  text-transform: uppercase;
  line-height: 30px;
  font-size: 14px;
}
.filter-parameters .filter-parameters__item.active {
  color: rgb(77, 119, 1);
}

.search-block-with-actions {
  display: flex;
  justify-content: space-between;
}
.search-block-with-actions .search-block-with-actions__actions {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.search-block {
  min-width: 300px;
  position: relative;
  display: flex;
  height: 3em;
  line-height: 3;
  overflow: hidden;
  border-radius: 10px;
  outline: 1px solid rgba(128, 115, 117, 0.5);
  transition: all 0.3s ease;
}
.search-block:after {
  content: "▼";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: rgba(128, 115, 117, 0.5);
  color: rgba(255, 255, 255, 0.95);
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}
.search-block:hover::after {
  color: rgb(128, 115, 117);
}
.search-block .search-block__select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  flex: 1;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  padding: 15px;
}
.search-block .search-block__select::-ms-expand {
  display: none;
}

.title-by-center {
  text-align: center;
  margin-bottom: 20px;
}
.title-by-center h2 {
  font-size: 22px;
  font-family: "Roboto";
  font-weight: 500;
  color: rgb(27, 44, 0);
}
.title-by-center h3 {
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 500;
  color: rgb(27, 44, 0);
}

.success-registration {
  position: relative;
  text-align: center;
}
.success-registration span.success-registration__content {
  padding-top: 20px;
  font-size: 16px;
  color: rgb(27, 44, 0);
  line-height: 1.15em;
  text-align: center;
}
.success-registration .orange-btn {
  margin: 30px auto 0;
}

.button-group {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.button-group > div:first-child {
  margin-right: 20px;
}

.success-registration__done {
  margin: 0 auto;
  width: 256px;
  height: 256px;
  background: url(/public/images/icons/done.png) no-repeat fixed center 0;
}

.pet-content__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.pet-content__wrapper.mobile {
  display: block;
}

.plain-form {
  flex: 2 1 610px;
  position: relative;
  max-width: 1200px;
}

.add-pet-step-info__wrapper {
  cursor: default;
  padding-left: 40px;
  flex: 0 1 330px;
}

.add-pet-step-info__wrapper.mobile {
  cursor: default;
  margin-bottom: 40px;
  padding-left: 0;
}

.add-pet-step-info {
  line-height: 40px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease 0s;
}
.add-pet-step-info .dot {
  height: 10px;
  width: 10px;
  background-color: rgba(128, 115, 117, 0.5);
  margin-right: 15px;
  border-radius: 50%;
  display: inline-block;
}
.add-pet-step-info .dot.active {
  background-color: rgb(27, 44, 0);
}

.add-pet-step-info__mobile {
  line-height: 40px;
  max-width: 250px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease 0s;
}
.add-pet-step-info__mobile .dot {
  height: 10px;
  width: 10px;
  background-color: rgba(128, 115, 117, 0.5);
  margin-right: 15px;
  border-radius: 50%;
  display: inline-block;
}
.add-pet-step-info__mobile .dot.active {
  background-color: rgb(27, 44, 0);
  transform: scale(130%);
}
.add-pet-step-info__mobile .dot:last-child {
  margin-right: 0;
}

.pet-step-link {
  list-style: none;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: rgba(128, 115, 117, 0.5);
}

.pet-step-link.mobile {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.pet-step-link.mobile span {
  letter-spacing: 0.12em;
}

.pet-step-link.active-step {
  color: rgb(27, 44, 0);
}

.extended-location-data {
  opacity: 0; /* Изначально скрыто */
  max-height: 0;
  transition: max-height 0.5s ease-out, opacity 0.5s ease;
}

.extended-location-data.visible {
  max-height: 600px;
  z-index: 100;
  opacity: 1; /* Появляется при выборе */
}

@media screen and (max-width: 1080px) {
  .top-menu,
  .login-section,
  .language-selector,
  .left-menu-wrapper,
  footer {
    display: none;
  }
  .content {
    min-height: 100vh;
  }
  body.lock {
    overflow: hidden;
  }
  .link {
    font-size: 18px;
  }
  .linkIcon {
    width: 20px;
    height: 20px;
    color: rgb(128, 115, 117);
    cursor: pointer;
    margin-right: 10px;
  }
  .mobile-section__link {
    position: relative;
    display: block;
    margin-bottom: 20px;
  }
  .mobile-section__link span {
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
    color: rgb(128, 115, 117);
    transition: all 0.3s ease 0s;
    cursor: pointer;
  }
  .mobile-section__link.active-link a {
    color: rgb(77, 119, 1);
  }
  .mobile-section {
    display: block;
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(27, 44, 0);
    padding: 80px 0 0 0;
    overflow: auto;
    transition: all 0.3s ease 0s;
  }
  .mobile-section .mobile-section__wrapper {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    padding-left: 21px;
    padding-top: 90px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    overflow: auto;
  }
  .mobile-section .mobile-section__wrapper .additionalInfo {
    position: absolute;
    bottom: 20px;
  }
  .mobile-section__links-block {
    position: relative;
  }
  .mobile-section__links-block .mobile-section__title {
    margin-bottom: 20px;
    cursor: pointer;
    z-index: 1;
  }
  .mobile-section__links-block .mobile-section__title span {
    font-size: 22px;
    font-weight: 500;
    color: rgb(128, 115, 117);
  }
  .mobile-section__links-block .mobile-section__links {
    max-height: 0;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    padding-left: 50px;
  }
  .mobile-section__links-block .mobile-section__links .mobile-section__link {
    position: static;
    display: none;
  }
  .mobile-section__links-block .mobile-section__links .mobile-section__link span {
    font-size: 18px;
  }
  .mobile-section__links-block .mobile-section__links .mobile-section__link.active-link a {
    color: rgb(77, 119, 1);
  }
  .mobile-section__links-block .mobile-section__links.active {
    max-height: 300px;
    opacity: 1;
  }
  .mobile-section__links-block .mobile-section__links.active .mobile-section__link {
    display: block;
  }
  .mobile-section__links-block .mobile-section__links.center {
    padding-left: 0;
    text-align: center;
  }
  .mobile-section.active {
    top: 0;
  }
  .logo {
    padding-top: 20px;
    padding-left: 20px;
    z-index: 5;
  }
  .logo span {
    font-size: 36px;
  }
  .logo.active {
    padding-top: 30px;
    padding-left: 25px;
  }
  .logo.active span {
    font-size: 30px;
  }
  .content {
    padding-top: 80px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .about-milkins {
    width: 100%;
    margin-top: 30px;
  }
  .promo-section {
    width: 95%;
  }
  .menu-logo-burger-container__burger {
    display: block;
    align-items: end;
    margin-right: 25px;
    margin-top: 38px;
    position: relative;
    width: 30px;
    height: 20px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    z-index: 5;
  }
  .menu-logo-burger-container__burger span {
    position: absolute;
    background-color: rgb(27, 44, 0);
    left: 0;
    width: 100%;
    height: 2px;
    top: 9px;
  }
  .menu-logo-burger-container__burger:before, .menu-logo-burger-container__burger:after {
    content: "";
    background-color: rgb(27, 44, 0);
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transition: all 0.3s ease 0s;
  }
  .menu-logo-burger-container__burger:before {
    top: 0;
  }
  .menu-logo-burger-container__burger:after {
    bottom: 0;
  }
  .menu-logo-burger-container__burger.active:before {
    transform: rotate(45deg);
    top: 9px;
    background-color: rgb(27, 44, 0);
  }
  .menu-logo-burger-container__burger.active:after {
    transform: rotate(-45deg);
    bottom: 9px;
    background-color: rgb(27, 44, 0);
  }
  .menu-logo-burger-container__burger.active span {
    transform: scale(0);
  }
  .top-menu ul.top-menu__links {
    margin-left: 20px;
    display: block;
  }
  .top-menu__item {
    margin: 0 0 20px 0;
  }
  .copyright-info {
    padding: 0 20px;
  }
  .copyright-info div {
    text-align: center;
  }
  .copyright-info div a.copyright-info__link {
    font-size: 14px;
  }
  .copyright-info span {
    font-size: 12px;
  }
  .popup .popup__body .popup__content {
    padding: 30px 15px;
  }
  .popup .popup__body {
    padding: 10px 15px;
  }
  .success-message {
    line-height: 25px;
    width: 100%;
    white-space: pre-wrap;
    text-align: center;
    margin-bottom: 30px;
  }
  .success-message span {
    color: rgb(77, 119, 1);
  }
  .table-line:hover {
    width: 100%;
  }
  .content .content-flex-wrapper {
    display: block;
  }
  .table-line__cell {
    padding: 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .table-line__cell span {
    font-size: 14px;
  }
  .table-line__cell .pet-logo span {
    font-size: 38px;
  }
  .table-line__cell.cell-75.pet-details-in-cell {
    width: 180px;
  }
  .table-line__cell.cell-75.pet-details-in-cell span {
    font-size: 18px;
  }
  .search-block {
    min-width: 230px;
  }
  .progress-bar .progress span {
    font-size: 14px;
    padding-left: 15px;
  }
  .progress-bar .progress.green {
    min-width: 33%;
  }
  .breed-info-block__wrapper .breed-info__section .breed-info__section-title {
    padding-left: 0;
  }
  .breed-info-block__wrapper .breed-info-block__aggregated-mark .aggregated-mark__mark-comment {
    padding-left: 15px;
    padding-right: 15px;
  }
  .breed-info-block__details {
    width: 100%;
  }
  .breed-info-block__details .breed-info-block__params {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .breed-info-block__details .breed-info-block__params .breed-info-block__params-interlayer .breed-info-block__params-param {
    width: 100%;
    min-width: 300px;
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .breed-info-block__details .breed-info-block__params .breed-info-block__params-interlayer .breed-info-block__params-param .param-label {
    width: 100%;
    display: flex; /* Добавляем flexbox */
    justify-content: flex-start; /* Выровнять содержимое по началу */
  }
  .breed-info-block__details .breed-info-block__params .breed-info-block__params-interlayer .breed-info-block__params-param .param-label span {
    line-height: 20px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(128, 115, 117);
  }
  .breed-info-block__details .breed-info-block__params .breed-info-block__params-interlayer .breed-info-block__params-param .progress-bar .progress span {
    font-size: 12px;
    padding-right: 20px;
  }
  .article-with-title {
    margin: 20px 0 0;
  }
  .search-block-with-actions .search-block-with-actions__actions {
    margin-right: 0;
  }
  .circle-photo__wrapper {
    margin-top: 15px;
  }
  .information-block-wrapper .information-block-content {
    padding: 13px;
  }
  .information-block-wrapper .information-block-content .information-block-content__header {
    font-size: 16px;
  }
  .information-block-wrapper .information-block-content .information-block-content__body .information-block-content__body-item .item-title {
    max-width: 125px;
    min-width: 125px;
  }
  .information-block-wrapper .information-block-content .information-block-content__body .information-block-content__body-item .item-title span {
    width: 115px;
  }
  .information-block-wrapper .information-block-content .information-block-content__body .information-block-content__body-item .item-content {
    font-size: 14px;
    display: flex;
    align-items: center;
    min-height: 40px;
  }
  .information-block-wrapper .information-block-content .information-block-content__body .information-block-content__body-item .item-content span {
    display: inline-block;
    vertical-align: center;
    line-height: 22px;
  }
  .section-title.breed {
    top: 20px;
  }
  .updated-element__wrapper {
    line-height: 40px;
  }
  .updated-element__wrapper .updated-element__header {
    font-size: 20px;
    line-height: 26px;
    margin-left: 0;
    margin-bottom: 26px;
  }
  .updated-element__wrapper .updated-element__header .fa-arrow-left {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .updated-element__wrapper .updated-element__header .fa-arrow-left:hover {
    color: rgb(244, 101, 0);
  }
  .updated-element__wrapper .updated-element__content {
    font-size: 14px;
    margin-left: 0;
  }
  .light-brown-btn {
    width: 136px;
    line-height: 50px;
    border-radius: 10px;
    background-color: rgb(128, 115, 117);
    color: rgb(255, 255, 255);
    cursor: pointer;
    outline: 2px solid rgb(128, 115, 117);
    text-align: center;
    position: relative;
    transition: all 0.3s ease 0s;
  }
  .light-brown-btn span.light-brown-btn__label {
    font-size: 16px;
  }
  .light-brown-btn:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(128, 115, 117);
  }
  .login-btn-wrapper {
    margin-bottom: 30px;
  }
  .pet-name-with-age {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    cursor: default;
  }
  .pet-name-with-age span {
    line-height: 30px;
    font-size: 22px;
    font-weight: bold;
    color: rgb(27, 44, 0);
  }
  .pet-name-with-age .pet-name-with-age__birthdate {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  .section-title {
    width: fit-content;
    margin: 0 auto;
    text-align: center;
  }
  .section-title .greetings {
    padding: 20px 0 30px 0;
    cursor: default;
  }
  .section-title .greetings h1 {
    font-size: 24px;
    font-weight: 900;
    color: rgb(27, 44, 0);
    /*color: $almostWhiteFilled;
    background-color: $darkGreenFilled;*/
  }
  .section-title.breed {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    width: auto;
    background: rgba(128, 115, 117, 0.3);
    letter-spacing: 0.03rem;
  }
  .section-title.breed .greetings {
    padding: 10px 0;
    line-height: 34px;
  }
  .section-title.breed .greetings h1 {
    font-size: 26px;
    background: none;
  }
  .section-title.breed .greetings._editable h1 {
    display: inline;
    padding-right: 10px;
  }
  .section-title.breed .greetings._editable a {
    cursor: pointer;
  }
  .section-title.breed .greetings._editable a img {
    height: 38px;
    width: 38px;
  }
  .breed-characteristics {
    position: absolute;
    right: 15px;
    top: 265px;
  }
  .breed-characteristics .breed-characteristics__item {
    background: rgba(128, 115, 117, 0.3);
    margin-bottom: 10px;
    max-width: 150px;
  }
  .breed-characteristics .breed-characteristics__item .breed-characteristics__item-header {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3em;
    color: rgb(255, 255, 255);
    margin-bottom: 2px;
  }
  .breed-characteristics .breed-characteristics__item .breed-characteristics__item-body {
    font-size: 14px;
    line-height: 1.3em;
    color: rgb(255, 255, 255);
  }
  .gender-params__wrapper {
    right: 15px;
    top: 245px;
    width: 140px;
  }
  .gender-params__wrapper .gender-params__params-section .gender-params__param {
    background-color: rgba(128, 115, 117, 0.3);
    display: flex;
    line-height: 36px;
  }
  .gender-params__wrapper .gender-params__params-section .gender-params__param .gender-params__param-icon {
    width: 40%;
    max-height: 40px;
    display: flex;
    align-items: center; /* Центрирование по вертикали */
    justify-content: center; /* Центрирование по горизонтали (если нужно) */
  }
  .gender-params__wrapper .gender-params__params-section .gender-params__param .gender-params__param-icon img {
    max-height: 26px;
    width: auto; /* Сохранение пропорций изображения */
    object-fit: contain; /* Вписывание картинки в контейнер */
  }
  .gender-params__wrapper .gender-params__params-section .gender-params__param .gender-params__param-data span {
    font-size: 12px;
    font-weight: 600;
  }
  .gender-params__wrapper .gender-params__params-section .gender-params__param:last-child {
    border-radius: 0 0 10px 10px;
  }
  .breed-info-block__wrapper .breed-info-block__preview .breed-primary-info span {
    line-height: normal;
  }
  .breed-info-block__wrapper .breed-info-block__preview .breed-primary-info span.breed__first-mentioned {
    display: inline-block;
    word-wrap: break-word; /* Перенос длинных слов на новую строку */
    overflow-wrap: break-word; /* Альтернативное свойство переноса */
    color: rgb(128, 115, 117);
    font-size: 12vw;
    font-weight: 500;
  }
  .breed-info-block__wrapper .breed-info-block__preview .breed-primary-info span.breed__origin_country {
    font-size: 9vw;
  }
  .breed-info-block__wrapper .breed-info-block__header span {
    text-align: center;
  }
  .information-block-content.no-borders {
    margin-top: 20px;
    padding: 0;
  }
  .information-block-content.no-borders .information-block-content__body .information-block-content__body-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    align-items: flex-start;
  }
  .information-block-content.no-borders .information-block-content__body .information-block-content__body-item .item-title {
    max-width: 100%;
    min-width: 0;
    display: flex;
    padding-left: 0;
  }
  .information-block-content.no-borders .information-block-content__body .information-block-content__body-item .item-title span {
    width: 100%;
  }
  .dictionary-element-header__wrapper {
    margin-bottom: 30px;
  }
  .dictionary-element-header__wrapper .dictionary-element-header {
    font-size: 32px;
    line-height: 50px;
    color: rgb(128, 115, 117);
    cursor: default;
    margin-left: 0;
  }
  .dictionary-element-header__wrapper .dictionary-element-header span {
    font-weight: 500;
  }
  .dictionary-element-header__wrapper .dictionary-element-header .fa-arrow-left {
    width: 32px;
    height: 32px;
    color: rgba(128, 115, 117, 0.5);
    cursor: pointer;
    margin-right: 10px;
  }
  .dictionary-element-header__wrapper .dictionary-element-header .fa-arrow-left:hover {
    color: rgb(128, 115, 117);
  }
  .dictionary-element-header__wrapper .dictionary-element-header .fa-dictionary-edit {
    width: 24px;
    height: 24px;
    line-height: 50px;
    color: rgba(128, 115, 117, 0.5);
    cursor: pointer;
    margin-left: 15px;
    margin-bottom: 5px;
  }
  .dictionary-element-header__wrapper .dictionary-element-header .fa-dictionary-edit:hover {
    color: rgb(128, 115, 117);
  }
}

